import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  keyLanguage = 'userLanguage';
  private _userLanguage = '';
  private supportedLanguages =  ['de']

  /**
   * @constructor
   * @param translate 
   */
  constructor(
    private translate: TranslateService,
  ) { 
    this.initLanguage(); 
    this.translate.use(this._userLanguage)
  }

  /**
  * set the application language to the default browser language
  */
  initLanguage() {
    const value = localStorage.getItem(this.keyLanguage);
    if (value != null && this.supportedLanguages.includes(value)) {
      this._userLanguage = value;
    } else {
      const browserLanguage = navigator.language.split('-')[0]; //fr-FR
      this._userLanguage = 'de'; // default
      if (this.supportedLanguages.includes(browserLanguage)) {
        this._userLanguage = browserLanguage;
        localStorage.setItem(this.keyLanguage, browserLanguage)
      }
    }
  }
}
