import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  static readonly accessToken = 'abble_access_token';

  constructor() { }
  
  /**
   * Add token to local storage
   * @param response user token
   */
  public tokenSetter(token: string) {
    sessionStorage.setItem(AuthService.accessToken, token);
  }
  
  /**
   * Remove token from local storage
   */
  public tokenRemover() {
    sessionStorage.removeItem(AuthService.accessToken);
  }
}
